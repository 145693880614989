import React, { Component } from "react"
import { jsx, css } from '@emotion/react'
import { navigate } from "../../helpers/queryNavigate"


// FINANCIERA COMPONENTS
import { useTheme } from '@findep/microfronts-core'
import { CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS
import { Grid, Container, Backdrop, Snackbar, Box } from "@material-ui/core"
import { Alert } from '@material-ui/lab';

//ICONS MATERIAL UI
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';

//SERVICIOS 
import { CataloguesService } from '../../services/LandingV4'



function VideoCallContent() {
  const { palette } = useTheme()

  const Rounded = css`
   background: ${palette.primary.main};
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;   
  `
  const cntTxtTwo = css`  
    width: 224px;  
    margin: 20px 20px 19px 16px;
    font-weight: normal;  
    color: ${palette.primary.main};
  `



  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={1}>
          <p css={Rounded}>1</p>
        </Grid>
        <Grid item  >
          <p css={cntTxtTwo}>
            Recuerda que los horarios de atención son: Lunes a Viernes 8:00am a 10:00pm y Sábado de 8:00am a 8:00pm.
          </p>

        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} >
        <Grid item xs={1}>
          <p css={Rounded}>2</p>
        </Grid>
        <Grid item >
          <p css={cntTxtTwo}>
            Al hacer clic en el botón, iniciarás la video llamada en otra pestaña de tu navegador.
          </p>

        </Grid>
      </Grid>
    </div>
  )
}

const sizeGrl = css`  
   font-size: 16px;
    `
const cntTxtOne = css`  
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        line-height: 1.5;
        letter-spacing: 0.15px;
    `
const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `
export default class TwilioVerifiy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flujo: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
      habilita: false,
      bdHabilita: false,
      errorService: false
    }
  }

  async stepCall() {

    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId

    this.setState({ habilita: true, bdHabilita: true })
    const catalogues = new CataloguesService();
    catalogues.identityVirtual(id)
      .then((response) => {
        let urlTwilio = response.data.verificationUrl
        window.location.assign(urlTwilio);
        navigate(this.props.pageContext.next.path)
        // window.location = urlTwilio


      })
      .catch(error => {
        this.setState({ errorService: true, habilita: false, bdHabilita: false })
      })

  }

  //INICIANDO EL READY 
  componentDidMount() {
    let fechaCad = new Date()
    let hora = fechaCad.getHours()
    let dia = fechaCad.getDay()

    let nhora = Number.parseInt(hora)

    // let nhora = 22

    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(fechaCad - tzoffset)).toISOString().slice(0, -1);

    if (dia !== 7) {
      if (dia === 6) {
        // console.log("Sabado")
        if (nhora < 8 || nhora > 19) {
          // console.log("hora no permitido")
          this.setState({ habilita: true });
        } else {
          this.setState({ habilita: false });
        }
      } else {
        // console.log("entre semana")
        if (nhora < 8 || nhora > 21) {
          // console.log("hora no permitido")
          this.setState({ habilita: true });
        } else {
          this.setState({ habilita: false });
        }
      }

    } else {
      this.setState({ habilita: true });
    }


  }


  render() {
    return (
      <Container maxWidth="sm" justify="center" css={sizeGrl}>

        <Grid container justify="center">
          <Grid item style={{ textAlign: "center" }}>
            <TitleIcon icon={SwitchVideoIcon} title="Video llamada" size={20} textColor />
          </Grid>
        </Grid>
        <br />
        <CardElevation>
          <Grid container>
            <Grid item  >
              <p css={cntTxtOne}>
                Para validar tu identidad te enlazaremos en una video llamada
              </p>
            </Grid>
          </Grid>
          <div>
            <VideoCallContent />
            <br />
            <Grid container spacing={4} justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <ButtonDegrade id="video-llamada-button" textButton='INICIAR VIDEO LLAMADA' onClick={() => this.stepCall()} disabled={this.state.habilita} icon={CameraEnhanceIcon} />
              </Grid>
            </Grid>
          </div>

        </CardElevation>
        <br />
        <CardElevation>
          <Grid container justify="center">
            <Grid item style={{ textAlign: "center" }}>
              <TitleIcon icon={WarningIcon} title="Recuerda" size={20} textColor />
              <br />
            </Grid>
            <br />
            <Box textAlign="center">
              Haz clic <a href="http://35.238.7.66/wp-content/uploads/2021/03/Videoverificacion.mp4" target="_blank" rel="nofollow noopener noreferrer">aquí</a> para ver un video que explica paso a paso el proceso de video verificación.<br />
              Recuerda que para continuar necesitas habilitar los permisos de tu cámara y estar en un lugar iluminado.
            </Box>
          </Grid>
        </CardElevation>
        <br />
        <Backdrop css={bDrop} open={this.state.bdHabilita} >
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            Lo sentimos, por el momento el servicio no se encuentra disponible
          </Alert>
        </Snackbar>

        &nbsp;

      </Container>


    )
  }
}